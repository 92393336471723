class BuchhaendlerListe extends HTMLElement {

  connectedCallback() {
    this.globaleVariablenDeklarieren();
    this.initScrollposition();
    this.initKontrollelemente();
    this.initObserver();
    this.initBuchhaendlerSelectionHandler();
  }

  globaleVariablenDeklarieren() {
      this.bilderSlider = this.querySelector('.slider-liste');
      this.sliderBox = this.querySelector('.slider-container');
      this.erstesSliderElement = this.querySelector('.slider-element:first-of-type');
      this.letztesSliderElement = this.querySelector('.slider-element:last-of-type');
      this.prev = this.querySelector('[interaction="vorherige-bilder-zeigen"]');
      this.next = this.querySelector('[interaction="naechste-bilder-zeigen"]');
      this.produktlisteContainer = this.querySelector('.produktliste-container');
  }

  initKontrollelemente() {
      if (this.prev) {
          this.prev.addEventListener('click', event => this.prevKlickHandler(event));
      }
      if (this.next) {
          this.next.addEventListener('click', event => this.nextKlickHandler(event));
      }
      if (this.bilderSlider) {
          this.bilderSlider.addEventListener('scroll', (event) => { this.scrollHandler(event) }, { once: true });
      }
  }

  scrollHandler(event) {
      if (event.target.scrollLeft > 0) {
          this.prev.setAttribute('data-status', 'aktiv');
      }
  }

  initScrollposition() {
      setTimeout(() => {
          const radioButtons = this.querySelectorAll('input[name="lbh"]');
          const initiallyCheckedRadioButton = this.querySelector('input[name="lbh"]:checked');

          const selectedIndex = Array.from(radioButtons).indexOf(initiallyCheckedRadioButton);
          const elementWidth = this.getFirstElementWidth();

          this.zurPositionScrollen(this.bilderSlider, {
            left: elementWidth * selectedIndex
          });
      }, 100);
  }

  prevKlickHandler(event) {
      this.zurPositionScrollen(this.bilderSlider, {
        left: this.bilderSlider.scrollLeft - this.calcScrollAmount()
      });
  }

  nextKlickHandler(event) {
      this.zurPositionScrollen(this.bilderSlider, {
        left: this.bilderSlider.scrollLeft + this.calcScrollAmount()
      });
  }

  calcScrollAmount() {
      const elementWidth = this.getFirstElementWidth();
      const positionsToScroll = Math.max(1, Math.floor(this.offsetWidth / elementWidth) - 1);
      return positionsToScroll * elementWidth;
  }

  getFirstElementWidth() {
      const elementStyle = this.erstesSliderElement.currentStyle || window.getComputedStyle(this.erstesSliderElement);
      const margin = parseFloat(elementStyle.marginLeft) + parseFloat(elementStyle.marginRight);
      return Math.abs(this.erstesSliderElement.offsetWidth + margin);
  }

  zurPositionScrollen(element, options) {
      element.scrollTo({
          top: options.top || 0,
          left: options.left || 0,
          behavior: 'smooth'
      });
  }

  initObserver() {
      this.options = {
          root: this.sliderBox,
          threshold: [0.95, 0.96, 0.97, 0.98, 0.99, 1.0]
      };
      this.observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => this.intersectionHandler(entry));
      }, this.options);
      this.querySelectorAll('.slider-element:first-of-type, .slider-element:last-of-type').forEach(element => this.observer.observe(element));
  }

  intersectionHandler(entry) {
      if (entry.target === this.erstesSliderElement && this.statusIstNichtInitial(this.prev)) {
          this.prev.setAttribute('data-status', entry.intersectionRatio === 1 ? 'inaktiv' : 'aktiv');
      }
      if (entry.target === this.letztesSliderElement) {
          this.next.setAttribute('data-status', entry.intersectionRatio === 1 ? 'inaktiv' : 'aktiv');
      }
  }

  statusIstNichtInitial(element) {
      return element.dataset['status'] !== undefined;
  }

  initBuchhaendlerSelectionHandler() {
      if (!this.produktlisteContainer) {
          return;
      }

      const fetchDetailsCallback = this.fetchDetails;
      const deeplinkDetails = this.dataset.deeplinkDetails;
      const container = this.produktlisteContainer;

      const radioButtons = this.querySelectorAll('input[name="lbh"]');

      for (const radioButton of radioButtons) {
          radioButton.addEventListener('change', function(e) {
              if (this.checked) {
                  fetchDetailsCallback(deeplinkDetails, this.id, container);
              }
          });
      }

      setTimeout(() => {
          const initiallyCheckedRadioButton = this.querySelector('input[name="lbh"]:checked');
          fetchDetailsCallback(deeplinkDetails, initiallyCheckedRadioButton.id, container);
      }, 100);
  }

  async fetchDetails(deeplinkDetails, bewerterId, container) {
      if (!deeplinkDetails || !bewerterId) {
          return;
      }

      fetch(deeplinkDetails.replace('{bewerterId}', bewerterId)).then(function(response) {
          if (!response.ok) {
              throw Error(response.statusText);
          }
          return response.text();
      }).then(function(responseText) {
          container.innerHTML = responseText;
      });
  }

}
  
if (!customElements.get("buchhaendler-liste")) {
  customElements.define("buchhaendler-liste", BuchhaendlerListe);
}
  